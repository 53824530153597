import { ElMessage } from "element-plus";
import 'element-plus/es/components/message/style/css'

export default {
  name: "detail",
  props: {
    currentId: {
      type: String
    }
  },
  data() {
    return {
      loading: false,
      data: {}
    };
  },
  beforeMount() {
    this.loading = true;
    this.$api.business.feeRule.detail(this.currentId).then(res => {
      if (res.code === 200) {
        this.data = res.data;
      } else if (res.code === 121601) {
        this.$router.replace({
          name: '404'
        });
      } else {
        ElMessage.error(res.message);
      }
    }).finally(() => {
      this.loading = false;
    });
  }
};