import "core-js/modules/es.array.push.js";
import { feeDesc, mixins } from "@/plugins/mixins";
import { ElLoading, ElMessage, ElMessageBox } from "element-plus";
import 'element-plus/es/components/loading/style/css'
import 'element-plus/es/components/message/style/css'
import 'element-plus/es/components/message-box/style/css'

import detail from './detail';
export default {
  name: "feeRule",
  mixins: [mixins],
  components: {
    detail
  },
  data() {
    return {
      currentId: null,
      detailDrawer: false,
      columns: [{
        type: 'expand',
        typeSlot: 'itemExpand'
      }, {
        label: '规则编码',
        prop: 'code',
        width: '100px'
      }, {
        label: '费用类型',
        prop: 'feeType',
        width: '100px'
      }, {
        label: '封顶金额',
        width: '100px',
        customRender: props => {
          if (props.row.maxFee > 0) {
            return props.row.maxFee;
          } else {
            return '不封顶';
          }
        }
      }, {
        label: '免费时长',
        width: '100px',
        customRender: props => {
          if (props.row.freeDuration > 0) {
            return `${props.row.freeDuration}分钟`;
          } else {
            return '无';
          }
        }
      }, {
        label: '收费时段',
        width: '120px',
        customRender: props => {
          return `${props.row.beginTime} ~ ${props.row.endTime}`;
        }
      }, {
        label: '费用描述',
        customRender: props => {
          return feeDesc(props.row);
        }
      }],
      topButtons: [{
        name: '新增',
        code: 'feeRule_add',
        type: 'primary',
        click: this.add,
        permissions: ['feeRule_add']
      }, {
        name: '导出',
        code: 'feeRuleExport',
        type: 'primary',
        click: this.inputInfo,
        permissions: ['feeRuleExport']
      }],
      linkButtons: [{
        name: '修改',
        code: 'feeRule_edit',
        click: this.edit,
        permissions: ['feeRule_edit']
      }, {
        name: '删除',
        code: 'feeRule_del',
        click: this.del,
        permissions: ['feeRule_del']
      }],
      searchItems: [{
        prop: 'code',
        label: '规则编码'
      }]
    };
  },
  methods: {
    inputInfo() {
      const loading = ElLoading.service();
      const search = this.$refs.tablePage.searchFormModel;
      this.$api.business.feeRule.exportInfo(search).then(res => {
        this.blobFile(res, '收费规则.xlsx', failData => {
          ElMessage.error(failData.message);
        }, () => {});
      }).finally(() => {
        loading.close();
      });
    },
    add() {
      this.$router.push('/feeRuleAdd');
    },
    edit(row) {
      this.$router.push({
        path: '/feeRuleAdd',
        query: {
          id: row.id
        }
      });
    },
    del(row) {
      ElMessageBox.confirm('确认要删除该收费规则吗?', '提示', {
        type: 'warning'
      }).then(() => {
        const loading = ElLoading.service();
        this.$api.business.feeRule.del(row.id).then(res => {
          if (res.code === 200) {
            ElMessage({
              message: res.message,
              type: 'success'
            });
            this.$refs.tablePage.fetch();
          } else {
            ElMessage.error(res.message);
          }
        }).finally(() => {
          loading.close();
        });
      });
    },
    detail(row) {
      this.detailDrawer = true;
      this.currentId = row.id;
    }
  }
};